.dd-wrapper{
    position: relative;
    align-items: stretch;
    font-size: 1.6rem;
    user-select: none;
    margin-bottom: 5px;
}

.dd-header{
    display: flex;
    color: #282c34;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border: 1px solid #282c34;
    border-radius: 3px;
    background-color: #FEFEFA;
    line-height: 38px;
    cursor: default;
    cursor: pointer;
    font-size: medium;
}

.dd-header-disabled{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border: 1px solid grey;
    color: grey;
    border-radius: 3px;
    background-color: #FEFEFA;
    line-height: 38px;
    cursor: default;
    cursor: pointer;
    font-size: medium;
}

.span{
    margin-right: 20px;
}

.dd-header-title{
    width: 100%;
    margin: 2px 10px;
    margin-right: 5px;
    font-weight: 300;
    text-align: left;
}

.angle{
    margin-right: 10px;
}

.dd-list{
    position: absolute;
    z-index: 10;
    overflow-y: scroll;
    width: 100%;
    max-height: 215px;
    color: #282c34;
    border: 1px solid rgb(223, 223, 223);
    border-top: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: 0 2px 5px -1px rgb(232, 232, 232);
    background-color: #FEFEFA;
    font-weight: 700;
    text-align: left;
    -webkit-overflow-scrolling: touch;
    padding-left: 15px;
}

.dd-scroll-list{
    overflow-y: scroll;
    max-height: 215px;
    padding: 15px 0;
}

.dd-list-item{
    display: inline-block;
    overflow: hidden;
    width: 100%;
    padding: 8px 1px;
    font-size: 1.5rem;
    line-height: 1.6rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: default;
    cursor: pointer;
    color: #282c34;
    font-size: small;
}

.no-result{
    font-weight: normal;
    cursor: default;
}

.selected{
    background-color: #FFCC01;
    color: #282c34;
}

.dd-list-search-bar{
    width: 100%;
    height: 40px;
    padding: 0 10px;
    border-top: none;
    border-right: none;
    border-left: none;
    font-size: inherit;
}

.placeholder{
    color: #282c34;
}

.searchable{
    overflow-y: hidden;
    padding: 0;
}

.dd-scroll-list{
    max-height: calc(215px - 40px);
    padding: 10px 0;
}