* {
  box-sizing: content-box;
}

.App {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
}

.main {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-left: 75px;
  padding-top: 80px;
  padding-right: 5px;
  overflow-x: hidden;
  height: 100vh;
  /* margin-top: 64px; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.App-link {
  color: #61dafb;
}

.header-row {
  height: 5vh;
  font-size: x-large;
  text-align: center;
}

.list-row {
  margin-bottom: 10px;
}

.dropdown-row {
  height: 3vh;
}

.map-row {
  height: 83vh;
  border-style: solid;
  border-width: 2px;
  border-color: #282c34;
  padding: 1px;
  border-radius: 10px;
}

.modal-map-row {
  height: 50vh;
}

.stretch-row {
  width: 100%;
}

.topbar-dropdown {
  color: white;
  background-color: #282c34;
  font-size: x-large;
  text-align: left;
  vertical-align: bottom;
}

.section-row {
  border-style: solid;
  border-width: 2px;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.section-header {
  font-size: xx-large;
  text-align: left;
}

.warning-message {
  color: red;
  font-style: italic;
}

.modal-text {
  color: #282c34;
  text-align: right;
}

.label {
  text-align: right;
}

.modal-section-header {
  color: #282c34;
  font-size: large;
  font-style: italic;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
